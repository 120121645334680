.login_page{
    margin-top: 140px;
}
.login_page__wrapper {
    display: flex;
    flex-direction: column;
  }
  
  
  .login_form {
    margin: 51px auto 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 255px;
  }
  .login_text{
    margin-top: 16px;
  }
  .login_form label {
    position: relative;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    font-family: var(--font-montserrat);
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
  }
  
  .login_form label input {
    margin-top: 10px;
    background: rgba(24, 26, 37, 0.5);
    border: 1px solid rgba(48, 50, 65, 0.5);
    border-radius: 6px;
  }
  
  .login_page img {
    margin-top: 38px;
    width: auto;
    max-width: 253px;
  }
  
  
  .login_page .btn_login.btn {
    margin-top: 38px;
    font-family: var(--font-montserrat);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  
  .btn_show {
    position: absolute;
    top: 46px;
    right: 17px;
    width: 13px;
    height: 9px;
    cursor: pointer;
  }
  
  .login_forget {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #3748FA;
  }
  
  .login_forget:hover {
    text-decoration: underline;
  }
  
  .login_other {
    margin: 50px auto 0;
    max-width: 576px;
    width: 100%;
  }
  
  .login_other span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    position: relative;
    font-family: var(--font-montserrat);
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
  }
  
  .login_other span::before,
  .login_other span::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: var(--font-color);
  }
  
  .login_other__items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  
  .login_btn_other {
    position: relative;
    padding: 12px 22px;
    margin-top: 24px;
    display: flex;
    align-items: center;
    gap: 16px;
    font-family: var(--font-montserrat);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    background: #1A1C2A;
    border-radius: 30px;
    color: var(--font-color);
    max-width: 280px;
    width: 100%;
  }
  
  .login_btn_other:nth-child(1)::before {
    content: url(../../assets/icons/facebook_login.png);
  }
  
  .login_btn_other:nth-child(2)::before {
    content: url(../../assets/icons/google_login.png);
  }
  
  .login_page .login_create {
    margin: 50px auto 0;
    font-family: var(--font-montserrat);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  
  .login_create a {
    color: #3478EB;
    text-decoration: underline;
  }

  @media screen and (max-width:767.98px) {
    .login_page{
        margin-top: 80px;
    }
     .btn_show{
        top: 43px;    
    }
    .login_page .login_form{
        margin: 45px auto 0;
    }
    .login_page .login_forget{
        margin-top: 39px;
    }
    .login_page .login_other span{
        display: none;
    }
    .login_other__items{
        flex-direction: column;
        gap: 0;
    }
    .login_page .login_create{
        margin: 35px auto 0;
    }
    .login_create{
        display: flex;
        flex-direction: column;
        text-align: center;
    }
  }