/* Hero Homepage */
.hero {
    margin: 40px 0 30px;
}

.hero__slider_top {
    border-radius: 2px;
}

.hero_top__card {
    position: relative;
}

.hero_top__content {
    position: absolute;
    left: 4%;
    bottom: 23%;
}

.hero_top__title {
    font-family: var(--font-montserrat);
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
}

.hero_top__descr {
    margin-top: 7px;
    max-width: 585px;
    font-family: var(--font-montserrat);
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
}

.hero_top__btn.btn {
    margin-top: 25px;
    padding: 14px 100px;
    font-family: var(--font-montserrat);
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}

.pagination_hero.swiper-pagination {
    bottom: 50px;
}

.pagination_hero .swiper-pagination-bullet {
    width: 33px;
    height: 2px;
    border-radius: 5px;
    background-color: var(--font-color);
    opacity: 1;
    -webkit-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
}

.pagination_hero .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 88px;
    background-color: #6532D1;
}

.hero_bottom {
    margin-top: 30px;
    gap: 14px;
}

.hero_bottom__item {
    position: relative;
    border-radius: 5px;
    width: 50%;
    height: 400px;
    overflow: hidden;
}

.hero_bottom__item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(19, 13, 37, 0)), color-stop(46.95%, rgba(19, 13, 37, 0.8)), to(#130D25));
    background: -o-linear-gradient(top, rgba(19, 13, 37, 0) 0%, rgba(19, 13, 37, 0.8) 46.95%, #130D25 100%);
    background: linear-gradient(180deg, rgba(19, 13, 37, 0) 0%, rgba(19, 13, 37, 0.8) 46.95%, #130D25 100%)
}

.hero_bottom__item.hero_bottom__item--comming {
    opacity: 0.2;
    pointer-events: none;
}

.hero_bottom__item img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

.hero_bottom__content {
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 24px;
}

.hero_bottom__title img {
    width: auto;
    margin-right: 10px;
}


.hero_bottom__text {
    margin: 16px 0 36px;
    font-family: var(--font-montserrat);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
}

.hero_bottom__btns {
    gap: 16px;
}

.hero_bottom__btn {
    gap: 7px;
}

.hero_bottom__btns__mobile {
    display: none;
}

.hero_bottom__btn {
    width: 50%;
}

.hero_bottom__item.hero_bottom__item--comming .hero_bottom__btn {
    width: 100%;
    text-transform: uppercase;
}

/* Hero Homepage END */

/* Slots */

.slots_top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 16px;
}

.slots__title {
    gap: 10px;
}

.slots__title img {
    width: auto;
}

.slots__descr {
    font-weight: 700;
    text-align: center;
}

.slots_bottom {
    margin-top: 30px;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fill, 217px);
    gap: 16px;
    place-content: center;
}

.slots_item {
    height: 300px;
    border-radius: 15px;
    overflow: hidden;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.slots_item:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
}

.slots_item img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.slots_btn.btn-green {
    margin: 30px auto 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding: 14px 54px;
}

/* Slots END */

@media screen and (max-width:979.98px) {
    .hero {
        margin-top: 0;
    }
    .hero .hero__container {
        padding: 0;
    }
    .hero_top__card {
        height: 700px;
        z-index: 20;
    }

    .hero_top__card img {
        height: 700px;
        object-fit: cover;
    }

    .hero_top__card::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 2;
    }

    .hero_top__card::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(../assets/homepage/slide_background_phone.png);
        z-index: 1;
    }

    .hero_top__content {
        top: 20%;
        bottom: auto;
        z-index: 47;
    }

    .pagination_hero.swiper-pagination {
        bottom: 100px;
    }

    .pagination_hero .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: var(--color-ping);
    }

    .hero_top__title {
        font-size: 30px;
        line-height: 37px;
    }

    .hero_top__title span {
        font-size: 45px;
        line-height: 55px
    }

    .hero_bottom {
        padding: 0 25px;
        margin-top: 10px;
        flex-direction: column-reverse;
    }

    .hero_bottom__item {
        width: 100%;
    }

    .hero_bottom__content .hero_bottom__btns {
        display: none;
    }

    .hero_bottom__btns.hero_bottom__btns__mobile {
        display: flex;
        margin-top: 15px;
    }

    .hero_bottom__item {
        overflow: auto;
        height: auto;
    }

    .hero_bottom__content {
        bottom: 35px;
    }

    .hero_bottom__item>img {
        height: 295px;
        border-radius: 8px;
    }

    .hero_bottom__item::before {
        height: 295px;
    }

    .hero_bottom__text {
        font-size: 14px;
        line-height: 17px;
        max-width: 340px;
    }

    .hero_bottom__item.hero_bottom__item--comming {
        opacity: 1;
        height: 225px;
        overflow: hidden;
    }

    .hero_bottom__item--comming .hero_bottom__content {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        gap: 10px;
        transform: translateY(-50%);
        z-index: 50;
    }

    .hero_bottom__item--comming .hero_bottom__text {
        display: none;
    }

    .hero_bottom__item.hero_bottom__item--comming .hero_bottom__btn {
        width: max-content;
        padding: 14px 52px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-transform: inherit;
    }

    .hero_bottom__item--comming::before {
        height: 225px;
        background: linear-gradient(180deg, rgba(19, 13, 37, 0.7) 0%, rgba(19, 13, 37, 0.8) 46.95%, #130D25 100%);
        z-index: 0;
    }

    .slots__descr {
        max-width: 490px;
    }
}

@media screen and (max-width:767.98px) {


    .hero_top__card,
    .hero_top__card img {
        height: 640px;
    }

    .hero_top__content {
        top: 13%;
    }

    .hero_top__descr {
        margin-top: 24px;
        max-width: 85%;
    }

    .pagination_hero.swiper-pagination {
        bottom: 30px;
    }

    .hero_bottom {
        padding: 0;
    }

    .hero_bottom__item.hero_bottom__item--comming,
    .hero_bottom__item--comming::before,
    .hero_bottom__item--comming>img {
        height: 175px;
    }

    .hero_bottom__title img {
        width: 20px;
    }

    .hero_bottom__item--comming .title_h2 {
        font-size: 18px;
        line-height: 22px;
    }

    .hero_bottom__item.hero_bottom__item--comming .hero_bottom__btn {
        padding: 9px 20px;
    }

    .hero_bottom__item>img,
    .hero_bottom__item::before {
        height: 220px;
    }

    .hero_bottom__btns.hero_bottom__btns__mobile {
        margin: 0;
        padding: 25px 25px 0;
    }

    .hero_bottom__btns .hero_bottom__btn {
        padding: 16px;
    }

    .hero_bottom__content {
        bottom: 85px;
    }

    .slots_top {
        gap: 10px;
    }

    .slots__title img {
        width: 18px;
    }

    .slots__descr {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
    }
    .slots_item{
        height: 200px;
    }
    .slots_bottom{
        margin-top: 20px;
        grid-template-columns: repeat(auto-fill, 144px);
        gap: 15px;
    }
}