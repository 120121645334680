/* Hero Blog  */
.hero_blog {
    position: relative;
    z-index: 10;
}

.hero_blog__wrapper {
    position: relative;
    height: 365px;
    overflow: hidden;
    border-radius: 8px;
}

/*.hero_blog__wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(19, 13, 37, 0)), color-stop(46.95%, rgba(19, 13, 37, 0.8)), to(#130D25)), url(.jpg);
    background: -o-linear-gradient(top, rgba(19, 13, 37, 0) 0%, rgba(19, 13, 37, 0.8) 46.95%, #130D25 100%), url(.jpg);
    background: linear-gradient(180deg, rgba(19, 13, 37, 0) 0%, rgba(19, 13, 37, 0.8) 46.95%, #130D25 100%), url(.jpg);

}*/

.hero_blog__img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
}

.hero_blog__content {
    position: absolute;
    left: 3%;
    bottom: 15%;
}

.hero_blog__title {
    gap: 15px;
}

.hero_blog__title img {
    width: auto;
}

.hero_blog__text {
    margin-top: 15px;
    font-family: var(--font-montserrat);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
}

/* Hero Blog END */



/* Blog Articles */

.blog_articles__items {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 35px;
    place-content: space-between;
}

.blog_article {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 15px;
}

.blog_article__image {
    height: 190px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 8px;
}

.blog_article__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
}

.blog_article__bottom {
    margin-top: auto;
    gap: 22px;
}

.blog_article__btn.btn {
    padding: 14px;
    width: 100%;
    max-width: 175px;
}

.blog_article time {
    font-family: var(--font-montserrat);
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}


/* Blog Articles END */

@media screen and (max-width:979.98px) {
    .blog_articles__items {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 70px;
        row-gap: 50px;
    }
}

@media screen and (max-width:767.98px) {
    .blog_articles__items {
        grid-template-columns: repeat(1, minmax(320px, 360px));
        row-gap: 30px;
        place-content: center;
    }

    .hero_blog__text {
        font-size: 14px;
        line-height: 17px;
    }
}