.dashboard__title {
    font-family: var(--font-montserrat);
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.dashboard__items {
    margin-top: 17px;
    /* display: grid;
    grid-template-columns: repeat(auto-fill, 340px);
    grid-gap: 10px; */
}

.dashboard__item {
    padding: 17px 19px;
    display: flex;
    flex-direction: column;
    background: #171822;
    border: 1px solid #303241;
    border-radius: 12px;
}

.slots_item__top {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.slots_item__top p,
.slots_item__center p,
.slots_item__bottom p {
    margin: 0;
}

.slots_item__top span {
    color: #22D49F;
}

.slots_item__top img {
    width: 32px;
    border-radius: 50%;
}

.slots_item__center {
    margin-top: 5px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #303241;
}

.slots_item__center span {
    font-weight: 600;
    color: var(--font-color);
}

.slots_item__bottom {
    margin-top: 15px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 16px;
    color: #9D9D9D;
}

.slots_item__bottom::before {
    content: '';
    margin-right: 2px;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.9516 1.7597C5.22177 1.7597 2.20142 4.78682 2.20142 8.51665C2.20142 12.2465 5.22177 15.2736 8.9516 15.2736C12.6882 15.2736 15.7153 12.2465 15.7153 8.51665C15.7153 4.78682 12.6882 1.7597 8.9516 1.7597ZM11.1814 11.6992L8.28267 8.79368V5.13818H9.63406V8.23961L12.1409 10.7464L11.1814 11.6992Z' fill='%239D9D9D'/%3E%3C/svg%3E%0A");
    background-position: center;
}

.dashboard__filter {
    margin-top: 20px;
    padding: 16px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    background: #1C1F2F;
    border-radius: 4px;
}


.slots_filter__item {
    position: relative;
    padding: 19px 16px;
    font-family: var(--font-montserrat);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    background-color: rgba(76, 82, 116, 0.21);
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.slots_filter__item:hover {
    background-color: rgba(239, 18, 151, 0.21);
}

.slots_filter__item.active {
    position: relative;
    background: rgba(239, 18, 151, 0.21);
    pointer-events: none;
}

.slots_filter__item.active::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 4px;
    padding: 1px;
    background: linear-gradient(45deg, #EF129736, #EF1297);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;

}

.slots_filter__item:not(:first-child) {
    max-width: 230px;
    width: 100%;
}

.slots_filter_search {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80px;
    width: 100%;
    padding: 17px;
    margin-left: auto;
    background: linear-gradient(180deg, #171822 0%, #171822 100%);
    border: 1px solid #303241;
    border-radius: 4px;
    transition: border 0.3s ease-in-out;
    cursor: pointer;
}

.slots_filter_search:hover {
    border: 1px solid #3e3f49;
}

.dashboard__bottom {
    margin-top: 40px;
}

.slots_bottom__title {
    font-family: var(--font-montserrat);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
}

.slots_items__login {
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 456px);
    place-content: center;
    row-gap: 30px;
    column-gap: 15px;
}

.slots_item__login {
    position: relative;
    max-height: 300px;
    border-radius: 8px;
}

.slots_item__login::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    filter: blur(30px);
    border-radius: 4px;
    z-index: -1;
    transition: background-color 0.3s ease-in-out;
}

.slots_item__login:hover::before {
    background-color: rgba(202, 28, 162, 0.5);
}

.slots_item__login img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.dashboard__filter .choices[data-type*=select-one] {
    flex-shrink: 0;
}

.dashboard__btn.btn {
    font-family: var(--font-montserrat);
    display: flex;
    max-width: 210px;
    margin: 50px auto 0;
    padding: 14px 50px;
    text-transform: uppercase;
}

.dashboard_tablet {
    display: none;
    margin: 34px 0;
}

.dashboard_form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.dashboard .dashboard_form input {
    width: 100%;
    padding: 20px 47px;
    font-family: var(--font-montserrat);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--font-color);
    background: #1C1F2F;
    border: 2px solid #2F2F45;
    box-shadow: 0px 5px 53px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
}

.dashboard .dashboard_form input::placeholder {
    color: var(--font-color);
}

.btn_seatch_tablet.btn {
    max-width: 125px;
    width: 100%;
    padding: 20px;
    background: #1C1F2F;
    border: 2px solid #2F2F45;
}

.btn_seatch_tablet.btn svg {
    width: 20px;
    height: 20px;
}

.dashboard .slots_filter_search span{
    display: none;
}
@media screen and (max-width:979.98px) {
    .dashboard_tablet{
        display: flex;
    }
    .dashboard .dashboard__filter.casino__filter{
        overflow: scroll;
        display: flex;
    }
    .dashboard .casino__filter .slots_filter__item:not(:nth-child(1)){
        min-width: 175px;
    }
    .dashboard .dashboard__filter.casino__filter .choices[data-type*=select-one] {
        max-width: 230px;
    }
    .slots .slots__container{
        padding: 0;
    }
    .dashboard .slots_filter_search{
        display: none;
    }
    
}
@media screen and (max-width:767.98px){
    .dashboard__items{
        margin-right: -12px;
    }
    .dashboard .dashboard_form input{
        padding: 20px 23px; 
    }
    .dashboard__items .swiper-slide{
        width: 330px;
    }
    .btn_seatch_tablet.btn {
        max-width: 60px;
    }
} 