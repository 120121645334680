.refund-wrapper {
    margin: 20px 20px 20px 20px;
    padding: 24px 24px;
    background-color: #1C1F2F;
    border-radius: 12px;
}

.refund-wrapper > h2 {
    font-weight: 700;
}

br {
    padding-bottom: 10px;
}