.reset__wrapper {
    padding: 0 36px;
}


.reset_form {
    font-family: var(--font-montserrat);
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}

.reset_form label {
    max-width: 330px;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
}

.page .reset_form input {
    padding: 9px 21px;
    margin-top: 10px;
    font-size: 14px;
    line-height: 32px;
}

.reset_bnt.btn {
    padding: 14px;
    max-width: 330px;
    width: 100%;
    margin: 70px auto 0;
    font-family: var(--font-montserrat);
}



.reset_pop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(24, 26, 37, 0.7);
}

.reset_pop__wrapper {
    max-width: 746px;
    max-height: 600px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #1C1F2F;
    border-radius: 15px;
}

.reset_pop__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reset_pop__content img {
    width: 100px;
}

.reset_pop__title {
    font-family: var(--font-montserrat);
    font-weight: 600;
    font-size: 30px;
    line-height: 130%;
}

.reset_pop__text {
    font-family: var(--font-montserrat);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.reset_pop__btn.btn {
    font-family: var(--font-montserrat);
    margin-top: 35px;
    max-width: 330px;
    width: 100%;
}

@media screen and (max-width:979.98px) {
    .reset__wrapper {
        padding: 0;
    }

    .reset_form label {
        max-width: 100%;
    }

    .reset_form {
        margin-top: 40px;
        gap: 24px;
    }

    .reset_bnt.btn {
        margin: 40px auto 0;
        max-width: 100%;
    }

}

@media screen and (max-width:767.98px) {
    .reset_form {
        margin-top: 30px;
        gap: 20px;
    }

    .reset_bnt.btn {
        margin: 30px auto 0;
    }
}