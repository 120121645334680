.footer {
    margin-top: 40px;
}

.footer_logo {
    /*min-width: 280px;*/
    min-width: 100px;
}

.footer_top__left {
    gap: 16px;
}

.footer_socials {
    gap: 16px;
}

.footer_socials a {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #18212A;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.footer_socials a img {
    width: auto;
}

.footer_socials a:hover {
    transform: translateY(-2px);
    background-color: var(--color-green);
}

.footer_top__btn.btn-blue {
    padding: 14px 32px;
}

.footer_top__btn img {
    width: auto;
    margin-right: 8px;
}

.footer_center {
    margin-top: 40px;
    padding: 48px 10px 30px 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    ;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    ;
}

.footer_menu {
    flex-wrap: wrap;
    width: 70%;
    gap: 10%;
}

.footer_center__items {
    max-width: 210px;
}

.footer_center__items li:first-child {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: var(--font-color);
    margin-bottom: 11px;
}

.footer_center__items a {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #9196A5;
    transition: color 0.2s linear;
}

.footer_center__items a:hover {
    color: var(--font-color);
}

.footer_center__image {
    width: auto;
    height: 100%;
}

.footer_center__bottom {
    margin-top: 40px;
    gap: 20px;
    font-family: var(--font-montserrat);
    font-weight: 500;
}

.footer_defens,
.footer_secure {
    width: auto;
    height: 100%;
}

.footer_attention {
    max-width: 615px;
    font-size: 16px;
    line-height: 20px;
    color: #D5D5D5;
}

.footer_rules {
    margin-top: 30px;
    font-size: 12px;
    line-height: 15px;
    color: #9196A5;
}

.footer_bottom {
    margin: 30px 0 40px;
}

.footer_bottom__copy {
    padding-left: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #9196A5;
}

.footer_bottom__crypto {
    gap: 22px;
    flex-wrap: wrap;
}

.footer_bottom__crypto img {
    max-width: 36px;
}

.footer_logo_tablet {
    display: none;
}

.footer_top__right {
    display: none;
}

@media screen and (max-width:979.98px) {
    .footer_bottom {
        margin: 66px 0 40px;
        padding: 17px 0 40px;
        flex-direction: column;
        border-top: 1px solid #2E2E33;
    }

    .footer_bottom__copy {
        font-size: 10px;
        line-height: 14px;
    }

    .footer_bottom__crypto {
        margin-top: 20px;
        gap: 16px;
    }

    .footer_bottom__crypto img {
        max-width: 27px;
    }

    .footer_center {
        margin-top: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column-reverse;
        border: 0;
    }

    .footer_center__bottom {
        order: 1;
    }

    .footer_center__top {
        margin-top: 55px;
    }

    .footer_center__image,
    .footer_defens,
    .footer_secure {
        display: none;
    }

    .footer_top__right .footer_center__image,
    .footer_top__right .footer_defens,
    .footer_top__right .footer_secure {
        display: flex;
    }

    .footer_center__items li:first-child {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 3px;
    }

    .footer_center__items a {
        font-size: 14px;
        line-height: 20px;
    }

    .footer_menu {
        width: 100%;
        gap: 30px;
        justify-content: space-between;
    }

    .footer_center__items:nth-child(2) {
        order: 2;
        max-width: 140px;
    }

    .footer_center__items:nth-child(4) {
        order: 3;
    }

    .footer_center__bottom {
        margin-top: 0;
        justify-content: center;
    }

    .footer_rules {
        margin-top: 0;
        text-align: center;
    }

    .footer_attention {
        order: 0;
        text-align: center;
    }

    .footer_logo {
        display: none;
    }

    .footer_logo_tablet {
        margin: 35px auto;
        display: flex;
        order: 1;
        /*max-width: 230px;*/
        max-width: 100px;
    }

    .footer_top {
        align-items: center;
    }

    .footer_top__right {
        display: flex;
        align-items: center;
        gap: 23px;
    }

    .footer_center__image__tablet {
        max-width: 57px;
    }
}

@media screen and (max-width:767.98px) {
    .footer_top {
        flex-direction: column;
    }
    .footer_top__btn.btn-blue{
        padding: 14px 10px;
    }
    .footer_top__right {
        margin-top: 36px;
    }
    .footer_center{
        padding: 20px 0;
        margin-top: 6px;
    }
    .footer_attention {
        font-size: 14px;
        line-height: 17px;
    }
    .footer_rules{
        color: #787878;
    }
    .footer_logo_tablet{
        margin: 26px auto;
    }
    .footer_center__top {
        margin-top: 30px;
    }
    .footer_center__items:nth-child(3){
        order: 2;
    }
    footer .footer_menu {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .footer_bottom {
        margin: 20px 0 130px;
        padding: 17px 0 0px;
    }
    .footer_bottom__crypto {
        gap: 14px;
    }
}