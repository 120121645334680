.support__wrapper {
    padding: 0 36px;
    max-width: 992px;
}

.support_table__heading {
    padding: 10px 20px;
}

.support_table {
    margin-top: 46px;
}

.support_table__heading .support_table_col {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #666873;
}

.support_table_row {
    padding: 15px 20px;
    background: #171822;
    border: 1px solid #303241;
    border-radius: 8px;
}

.support_table_row .support_table_col {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}


.support_commission .support_table__heading,
.support_commission .support_table_row {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 20% 20% 20% 20% 20%;
    grid-template-columns: 20% 20% 20% 20% 20%;
}

.commission_not_found {
    min-height: 150px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #171822;
    border: 1px solid #303241;
    border-radius: 8px;
}

.commission_not_found img {
    display: flex;
    width: 36px;
}

.commission_not_found p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #666873;
}

.support_btn.btn {
    padding: 13px 65px;
    font-family: var(--font-montserrat);
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    background: rgba(40, 43, 60, 0.3);
    border-radius: 4px;
}



.support_pop {
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(48,50, 65, 0.5);
    z-index: 9999; */
}

.support_wrapper {
    padding: 15px;
    flex-direction: column;
    height: 100%;
}

.form_title {}

.support_title {}

.support_text {}

.form_text {}

.support_form {
    margin-top: 50px;
    flex-wrap: wrap;
    width: 100%;
    max-width: 730px;
    gap: 10%;
}

.support_form label:not(:has(textarea)) {
    width: 45%;
    margin-bottom: 30px;
}

.support_form label {
    display: flex;
    flex-direction: column;
    font-family: var(--font-montserrat);
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
}

.support_form label input {
    padding: 15px 18px !important;
    color: var(--font-color) !important;
}

.support_form label select,
.support_form label textarea {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 15px 18px;
    background: rgba(24, 26, 37, 0.5);
    border: 1px solid rgba(48, 50, 65, 0.5);
    border-radius: 6px;
    color: #666873;
    outline: none;
    font-size: 12px;
    line-height: 1;
    color: var(--font-color);
}

.support_form label:has(textarea) {
    width: 100%;
}

.support_form label input::placeholder,
.support_form label textarea::placeholder {
    color: rgba(128, 135, 146, 0.5);
}

.support_form label textarea {
    margin-top: 10px;
    resize: none;
    min-height: 150px;
}

.support_form label select option {
    position: relative;
    margin-top: 6px;
    font-family: var(--font-montserrat);
    font-size: 12px;
    line-height: 1;
    background-color: transparent;
    border: 0;
    color: var(--font-color);
    outline: none;
    z-index: 20;
    color: var(--font-color);
}

.support_form label input {
    font-size: 12px !important;
    line-height: 1 !important;
    font-family: var(--font-montserrat);
}

.support_form label input,
.support_form label select {
    margin-top: 10px;
    font-family: var(--font-montserrat);
}

.support_bottom {
    margin-top: 20px;
    width: 100%;
}

.support_file {
    display: flex;
    flex-direction: column;
}

.support_file input[type="file"] {
    display: none;
}

.support_add.btn {
    padding: 10px 20px;
    background-color: transparent;
    font-family: var(--font-montserrat);
    font-weight: 400;
    font-size: 9.88px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #A6A9B3;
    border: 2px solid transparent;
    transition: border 0.3s ease-in-out, color 0.3s ease-in-out;
}

.support_add.btn:hover {
    border: 2px solid rgba(48, 50, 65, 0.5);
    color: var(--font-color);
}


.support_fake_btn {
    max-width: 210px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: rgba(40, 43, 60, 0.3);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.support_fake_btn:hover {
    background-color: rgba(40, 43, 60, 0.7);
}

.support_submit.btn {
    margin-top: 50px;
    width: 100%;
    max-width: 330px;
    margin: 50px auto 0;
    font-family: var(--font-montserrat);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}

@media screen and (max-width:979.98px) {
    .support__wrapper {
        padding: 0;
    }

    .support_table {
        width: 900px;
    }

    .form_title,
    .form_text {
        align-self: flex-start;
    }

    .support_submit.btn {
        max-width: 100%;
    }

}

@media screen and (max-width:767.98px) {
    .support_content {
        flex-direction: column;
        gap: 16px;
    }

    .support_btn.btn {
        max-width: 100%;
        width: 100%;
    }

    .support_form label:not(:has(textarea)) {
        width: 100%;
        margin-bottom: 20px;
    }

    .support_bottom {
        flex-direction: column;
    }

    .support_file {
        width: 100%;
    }

    .support_fake_btn {
        width: 100%;
        max-width: 100%;
    }

    .support_filename {
        font-size: 10px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #A6A9B3;
    }
    .support_add.btn{
        margin-top: 15px;
    }
    .support_form{
        margin-top: 25px;
    }
}