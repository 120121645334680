.referals {
    margin: 90px 0 0;
}

.referals .referals__container {
    max-width: 986px;
}

.referals__title {
    font-family: var(--font-montserrat);
    font-weight: 800;
    font-size: 60px;
    line-height: 73px;
    text-align: center;
    color: var(--color-ping);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 5px 4px 0px rgba(175, 83, 255, 0.4);
    text-transform: uppercase;
}

.referals__descr {
    padding: 50px 20px;
    margin-top: 45px;
    background: #181A25;
    border: 3px solid #FF3CBE;
    border-radius: 20px;
    font-family: var(--font-montserrat);
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
}

.referals__descr span {
    display: flex;
    max-width: 540px;
    margin: 0 auto;
}

.referals__link {
    position: relative;
    margin-top: 44px;
    padding: 27px;
    background: #EF1297;
    border: 1px solid #000000;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: lowercase;
    filter: drop-shadow(10px 10px 0px rgba(175, 83, 255, 0.4));

}

.referals__link::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 100%;
    height: 100%;
    background: rgba(175, 83, 255, 0.3);
    filter: blur(30px);
    z-index: -1;
}

.referals_contacts {
    margin-top: 90px;
    text-align: center;
}

.referals__items {
    gap: 30px;
}

.referals__items a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.referals__items a img {
    width: auto;
}

.referals__items a:hover {
    background-color: var(--color-ping)
}

.referals_btn {
    margin-top: 25px;
    display: inline-flex;
    padding: 14px 51px;
    font-family: var(--font-montserrat);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: var(--font-color);
    background: rgba(40, 43, 60, 0.3);
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
}

.referals_btn:hover {
    background-color: var(--color-green)
}

.referals_stat {
    margin-top: 46px;
    text-align: center;
    gap: 125px;
}

.referals_stat__left,
.referals_stat__right {
    font-family: var(--font-montserrat);
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
}

.referals_stat__reg,
.referals_stat__suc {
    margin-bottom: 20px;
}

.referals_stat__suc {
    color: var(--color-green);
}

.referals_table {
    margin: 50px auto 0;
    max-width: 865px;
    display: grid;
    grid-gap: 4px;
}

.referals_table__heading {
    padding: 10px 20px;
}

.referals_table__heading,
.referals_table_row {
    display: grid;
    grid-template-columns: 70% 30%;
}

.referals_table__heading .referals_table_col {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #666873;
}

.referals_table_row {
    padding: 15px 20px;
    background: #171822;
    border: 1px solid #303241;
    border-radius: 8px;
}

.referals_table_row .referals_table_col {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.referals_table_row .referals_table_col:first-child {
    color: #71A8FE;
}

@media screen and (max-width:767.98px) {
    .referals__title {
        font-size: 30px;
        line-height: 37px;
    }

    .referals__descr {
        font-size: 18.75px;
        line-height: 23px;
    }

    .referals__link {
        padding: 22px;
        font-size: 12px;
        line-height: 15px;
    }

    .referals__link__btn {
        display: none;
    }

    .referals_stat {
        gap: 40px;
    }

    .referals_stat__left,
    .referals_stat__right {
        font-size: 22px;
        line-height: 27px;
    }
    .referals_table{
        margin: 30px auto 0;
    }
    .referals_table__heading, .referals_table_row{
        grid-template-columns: 50% 50%;
    }
}